import React from "react"
import { Link } from "gatsby"

import Layout from "../../../../components/layout"
import SEO from "../../../../components/seo"

import BookingForm from "../../../../components/dynamic/BookingForm"

function Blank(props) {
  return (
    <>
      <SEO title="Milford Sound Day Tour and Cruise From Te Anau - awesomeNZ" />
      <Layout
        layoutType="typography TourPage"
        slideshow={
          <ul class="slideshow-slides">
            <li class="slideshow-slide first">
              <img
                src={require("../../../../images/sliders/milford-sound-day-tour-te-anau.jpg")}
                alt=""
                class="first image"
              />
            </li>
            <li class="slideshow-slide">
              <img
                src={require("../../../../images/sliders/coach.jpg")}
                alt=""
                class="image"
              />
            </li>
            <li class="slideshow-slide">
              <img
                src={require("../../../../images/sliders/milford-sound-day-tour-cruise.jpg")}
                alt=""
                class="last image"
              />
            </li>
          </ul>
        }
        hasSliderHandles={true}
        hasSliderBlurLarge={true}
        content={
          <>
            <BookingForm tourCode="A88L" />

            <div id="row" class="fourteen clearfix">
              <div class="title-div clearfix">
                <h1>Milford Sound Day Trip From Te Anau</h1>
                <h3>
                  Immerse yourself in breathtaking beauty on this full day tour
                </h3>
                <div class="highlights">
                  <ul>
                    <li>1 hour 45 minute Milford Sound cruise</li>
                    <li>Glass roof coaches for optimal viewing</li>
                    <li>Awesome local guides!</li>
                    <li>FREE delicious lunch</li>
                  </ul>
                </div>
              </div>
              <div id="Overview" class="clearfix">
                <div id="Content" class="clearfix">
                  <div id="ContentTab">
                    <p>
                      <strong>UPDATE:</strong>
                      <span>
                        &nbsp;
                        <em>
                          The Milford Road is now open to tour coaches
                          travelling in convoy.&nbsp;There will be limited photo
                          stops on the way to Milford Sound: Mirror Lakes and
                          Eglinton Valley will be the only planned stops. If
                          time allows we may make additional stops.
                        </em>
                      </span>
                    </p>
                    <p>
                      Flanked by rainforest and spectacular mountain ranges,
                      Milford Sound is one of the world's most spectacular
                      natural environments. <strong>awesomeNZ</strong>
                      &nbsp;Milford Sound day trips from Te Anau depart daily –
                      jump on board and enjoy what will be the highlight of your
                      New Zealand experience.
                      <br />
                      <br />
                      Our experienced local guides will keep you entertained
                      throughout the day. Passionate about this part of the
                      world they call home, they'll provide plenty of
                      opportunities for photo stops and ensure you have a truly
                      awesome Milford Sound experience! Check out what others
                      are saying about our tour by clicking on the Reviews tab
                      above.
                      <br />
                      <br />
                      Joining the tour in <strong>Te Anau</strong>, you will
                      experience the beauty of{" "}
                      <strong>Fiordland National Park</strong>, a region rich
                      with dense native bush, lakes and waterfalls cascading
                      from rugged mountain peaks. Our glass roof coaches ensure
                      you don't miss the stunning views as you pass through the{" "}
                      <strong>Eglinton Valley</strong> with its ancient alpine
                      forest and the famous <strong>Mirror Lakes</strong>, where
                      our Milford Sound tour pauses for photo opportunities.{" "}
                      <br />
                      <br />
                      From here, the Milford Sound tour progresses through the
                      Upper Hollyford Valley and past the stunning Christie
                      Falls. As we exit the 1.2km long{" "}
                      <strong>Homer Tunnel</strong> (drilled through the solid
                      rock of a mountain) our tour enters the breathtaking
                      Cleddau Valley and then descends into{" "}
                      <strong>Milford Sound</strong> where you'll board a
                      spectacular 1 hour 45 minute boat cruise.
                      <br />
                      <br /> Tuck into a <strong>delicious lunch</strong>{" "}
                      (included in your tour) as you travel the length of the
                      fiord, keeping an eye out for dolphins, seals, penguins
                      and other marine life. See natural wonders like{" "}
                      <strong> Mitre Peak</strong>, glacier-scarred rock walls
                      rising 1,200m vertically from the sea, and waterfalls that
                      tumble into the sound from valleys high above.
                      <br />
                      <br />
                      After your Milford Sound cruise, your guide will be
                      waiting to drive you back to Te Anau, arriving late
                      afternoon.
                    </p>

                    <div class="clear"></div>

                    <h2 class="tour">FREE Lunch</h2>
                    <p>
                      With every cruise you'll receive a delicious free lunch,
                      served fresh and hot! Choose from:
                    </p>
                    <ul>
                      <li>
                        Pizza Pie (sesame and soy chicken with cabbage, carrots,
                        garlic and ginger)
                      </li>
                      <li>
                        Little India Meal (turmeric and cumin spiced lentil and
                        potato curry with steamed vegetables and rice
                        (vegetarian, gluten free, dairy free)
                      </li>
                    </ul>
                    <p>
                      Your driver guide will take your order on the day and your
                      meal will be delivered to you when you board your cruise.
                    </p>

                    <div class="clear"></div>
                  </div>
                </div>
              </div>
              <div class="GSRelatedProducts clearfix">
                <h2 class="tour">Tours You May Also Like</h2>

                <div class="tour-item">
                  <Link to="/home/new-zealand-tours/milford-sound-tours/sundowner-tour-from-te-anau">
                    <div class="tour-image">
                      <img
                        alt=""
                        src={require("../../../../images/tours/awesomenz-milford-sound-sundowner-cruise-te-anau.jpg")}
                      />
                    </div>
                  </Link>
                  <div class="tour-details">
                    <h2>Milford Sound Sundowner Tour From Te Anau</h2>
                    <div class="tour-details-prices">
                      <span class="tour-details-prices-from">From </span>{" "}
                      <span class="tour-details-prices-price">$129.00</span>
                    </div>
                    <span class="clearfix">
                      <Link to="/home/new-zealand-tours/milford-sound-tours/sundowner-tour-from-te-anau">
                        Find out more &gt;
                      </Link>
                    </span>
                  </div>
                </div>
                <div class="tour-item">
                  <Link to="/home/new-zealand-tours/milford-sound-tours/milford-sound-day-tour-from-queenstown">
                    <div class="tour-image">
                      <img
                        alt=""
                        src={require("../../../../images/thumbs/milfordsound.jpg")}
                      />
                    </div>
                  </Link>
                  <div class="tour-details">
                    <h2>Milford Soudn Day Tour From Queenstown</h2>
                    <div class="tour-details-prices">
                      <span class="tour-details-prices-from">From </span>{" "}
                      <span class="tour-details-prices-price">$189.00</span>
                    </div>
                    <span class="clearfix">
                      <Link to="/home/new-zealand-tours/milford-sound-tours/milford-sound-day-tour-from-queenstown">
                        Find out more &gt;
                      </Link>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </>
        }
      />
    </>
  )
}

export default Blank
